import { useState, useEffect, useCallback, useRef } from "react"

interface PageableResponse<T> {
    records: T[]
    skipToken?: string
}

interface UsePageableOptions {
    initialSkipToken?: string
    pageSize?: number
}

interface UsePageableResult<T> {
    data: T[]
    loading: boolean
    error: Error | null
    loadMore: () => void
    hasMore: boolean
}

/**
 * Custom hook to fetch paginated data using skip tokens.
 *
 * @template T The type of data items.
 * @param {string} endpoint The API endpoint to fetch data from.
 * @param {UsePageableOptions} options Configuration options.
 * @returns {UsePageableResult<T>} The hook's return values.
 */
function usePageable<T>(
    endpoint: string,
    options: UsePageableOptions = {}
): UsePageableResult<T> {
    const { initialSkipToken = "", pageSize = 20 } = options

    const [data, setData] = useState<T[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<Error | null>(null)
    const [currentSkipToken, setCurrentSkipToken] = useState<string | null>(
        initialSkipToken || null
    )
    const [nextSkipToken, setNextSkipToken] = useState<string | null>(null)
    const [hasMore, setHasMore] = useState<boolean>(true)

    // Keep track of the previous endpoint to detect changes
    const prevEndpointRef = useRef<string>(endpoint)

    const abortController = new AbortController()

    /**
     * Fetch data from the API.
     *
     * @param {string | null} skipToken The skip token for pagination.
     */
    const fetchData = useCallback(
        async (skipToken: string | null) => {
            if (!hasMore && !skipToken) return // No more data to fetch

            setLoading(true)
            setError(null)

            try {
                const url = new URL(endpoint, window.location.origin)
                if (skipToken) {
                    url.searchParams.append("skipToken", skipToken)
                }
                url.searchParams.append("limit", pageSize.toString())

                const response = await fetch(url.toString(), {
                    signal: abortController.signal,
                })

                if (!response.ok) {
                    throw new Error(
                        `Error: ${response.status} ${response.statusText}`
                    )
                }

                const result: PageableResponse<T> = await response.json()

                setData((prevData) => [...prevData, ...result.records])
                setNextSkipToken(result.skipToken || null)
                setHasMore(!!result.skipToken)
            } catch (err: any) {
                setError(err)
            } finally {
                setLoading(false)
            }
        },
        [endpoint, pageSize, hasMore]
    )

    /**
     * Load the next page of data.
     */
    const loadMore = useCallback(() => {
        if (loading || !hasMore) return
        fetchData(nextSkipToken)
        setCurrentSkipToken(nextSkipToken)
    }, [fetchData, nextSkipToken, loading, hasMore])

    /**
     * Reset the hook's state when the endpoint changes.
     */
    const resetState = useCallback(() => {
        setData([])
        setCurrentSkipToken(initialSkipToken || null)
        setNextSkipToken(null)
        setHasMore(true)
        setError(null)
    }, [initialSkipToken])

    /**
     * Effect to handle initial fetch and endpoint changes.
     */
    useEffect(() => {
        if (prevEndpointRef.current !== endpoint) {
            // Endpoint has changed, reset state
            resetState()
            prevEndpointRef.current = endpoint
        }

        // Fetch data if data is empty (initial load or after reset)
        if (data.length === 0 && hasMore && !loading) {
            fetchData(currentSkipToken)
        }
    }, [
        endpoint,
        fetchData,
        currentSkipToken,
        data.length,
        hasMore,
        loading,
        resetState,
    ])

    return {
        data,
        loading,
        error,
        loadMore,
        hasMore,
    }
}

export default usePageable
